import * as React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import PageHeader from "../../components/pageheader"
import { graphql } from "gatsby"

const MasterPlanPage = ({ data }) => {
    return (
        <Layout activeMenuId={6}>
            <Seo
                title={data?.wpPage?.seo?.title}
                description={data?.wpPage?.seo?.metaDesc}
                date={data?.wpPage?.date}
                img={data?.wpPage?.seo?.opengraphImage?.mediaItemUrl}
                imgHeight={
                    data?.wpPage?.seo?.opengraphImage?.mediaDetails?.height
                }
                imgWidth={
                    data?.wpPage?.seo?.opengraphImage?.mediaDetails?.width
                }
            />
            {data?.wpPage?.blocks.map((item, i) => {
                switch (item?.name) {
                    case "acf/cover-main":
                        const coverMain = item?.coverMain
                        return (
                            <PageHeader
                                hasUnderline
                                key={i}
                                hasUnderline
                                title={coverMain?.title}
                                subTitle={coverMain?.text}
                                image={coverMain?.img?.mediaItemUrl}
                                breadcrumbs={[
                                    {
                                        label: `${coverMain?.title}`,
                                        link: null,
                                    },
                                ]}
                            />
                        )
                    case "acf/acfimage":
                        const imageBlock = item.imageBlock.img
                        return (
                            <div key={i} className="container mx-auto">
                                <div className="text-center">
                                    <div className="mb-16">
                                        <img
                                            src={imageBlock.mediaItemUrl}
                                            alt={imageBlock.altText}
                                            className="mx-auto"
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                }
            })}
        </Layout>
    )
}

export const query = graphql`
    {
        wpPage(slug: { eq: "masterplan" }) {
            date
            seo {
                title
                metaDesc
                opengraphImage {
                    id
                    mediaItemUrl
                    mediaDetails {
                        height
                        width
                    }
                }
            }
            blocks {
                name
                ... on WpAcfCoverMainBlock {
                    coverMain {
                        img {
                            altText
                            mediaItemUrl
                        }
                        title
                        text
                    }
                }
                ... on WpAcfAcfimageBlock {
                    imageBlock {
                        img {
                            altText
                            caption
                            sourceUrl
                            mediaItemUrl
                        }
                    }
                }
            }
        }
    }
`

export default MasterPlanPage
